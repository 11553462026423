import React from 'react';
import { Helmet } from 'react-helmet';

const BreadcrumbData = ({ title, pathname, parentName, parentURL }) => {

  return(
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.iliadgrow.com/"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "${parentName || title}",
                "item": "${parentURL ? `https://www.iliadgrow.com${parentURL}` : `https://www.iliadgrow.com${pathname}`}"
              }${parentName || parentURL ? (`,{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "${title}",
                  "item": "https://www.iliadgrow.com${pathname}"
                }
              `) : (
                ''
              )}
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default BreadcrumbData;
