import React from 'react'
import { Link } from 'gatsby'
import { MdChevronRight } from 'react-icons/md'

import BreadcrumbData from '../StructuredData/BreadcrumbData'
import './Breadcrumb.scss'

const Breadcrumb = ({ parentName, parentURL, title, pathname }) => {
  return (
    <>
      <BreadcrumbData
        parentName={parentName}
        parentURL={parentURL}
        title={title}
        pathname={pathname}
      />
      <ol className='iliad__breadcrumb'>
        <li>
          <Link to='/'>Home</Link> <MdChevronRight />
        </li>
        {parentName && parentURL && (
          <li>
            <Link to={parentURL}>{parentName}</Link> <MdChevronRight />
          </li>
        )}
      </ol>
    </>
  )
}

export default Breadcrumb
